import { useContext } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import moment from "moment-timezone";
import { LEAGUE_CONFIG_QUERY, INFINITE_ARTICLES_QUERY } from "src/gql/queries";
import { currentSport } from "src/services/ApolloService";
import { WebSportItem, WEB_SPORTS_LIST, WEB_SPORTS_MAP, DEFAULT_TIMEZONE } from "src/constants";
import { SubscriptionContext } from "./SubscriptionService";
import { initializeApollo } from "src/lib/apollo-client";

/**
 * The raw league config content type
 */
export const useLeagueConfig = ({ ssr }: { ssr?: boolean } = {}) => {
  const ctx = useContext(SubscriptionContext);
  const league = useReactiveVar(currentSport);

  const { data } = useQuery(LEAGUE_CONFIG_QUERY, {
    ssr,
    fetchPolicy: "cache-and-network",
    variables: {
      league: league?.url || "none",
      includeSurveyResponses: ctx.signedIn,
    },
  });

  return data?.prismicAllLeague_configs.edges[0]?.node || null;
};

const prepareFeaturedContent = (leagueConfig: any) => {
  let featuredContentList: any[] = leagueConfig?.featured_content || []; // eslint-disable-line

  featuredContentList = featuredContentList.filter((featuredItem) => {
    // Filter out mobile only content
    const locationMatch = ["all", "all web"].includes(featuredItem.location);
    return locationMatch;
  });

  // Remap and filter featured content
  return featuredContentList.map((featuredItem) => featuredItem.content).filter((featuredItem) => featuredItem);
};

/**
 * All featured content items (not used in its raw form on web)
 */
const useFeaturedContent = ({ ssr }: { ssr?: boolean } = {}) => {
  const leagueConfig = useLeagueConfig({ ssr });
  return prepareFeaturedContent(leagueConfig);
};

/**
 * Articles in featured content
 */
export const useFeaturedArticles = ({ ssr }: { ssr?: boolean } = {}) => {
  const featuredContent = useFeaturedContent({ ssr });
  return featuredContent.filter((x) => x._meta.type === "news_basic");
};

/**
 * Most recently published articles.
 */
export const useRecentArticles = ({ ssr, where = {} }: { ssr?: boolean; where?: any } = {}) => {
  const first = 9; // keep this to max needed to prevent sending multiple queries and splitting relay cache by keyArgs "first"

  const league = useReactiveVar(currentSport);

  const response = useQuery(INFINITE_ARTICLES_QUERY, {
    ssr,
    variables: {
      first,
      after: null,
      where: {
        hidden: false,
        league: league?.url ?? undefined,
        ...where,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const articles = response.data?.prismicAllNews_basics.edges.map((e: any) => e.node) || []; // eslint-disable-line

  const pageInfo = response.data?.prismicAllNews_basics.pageInfo;

  const hasMore = pageInfo?.hasNextPage;

  const loadMore = async () => {
    if (!hasMore) {
      return;
    }

    await response.fetchMore({
      variables: {
        first,
        after: pageInfo?.endCursor,
        where: {
          hidden: false,
          league: league?.url ?? undefined,
          ...where,
        },
      },
    });
  };

  return { articles, response, hasMore, loadMore };
};

/**
 * Blended featured and recent articles
 */
export const useArticles = ({ ssr }: { ssr?: boolean } = {}) => {
  const featuredArticles = useFeaturedArticles({ ssr });
  const recentArticles = useRecentArticles({ ssr });
  return dedupeArticles(featuredArticles.concat(recentArticles.articles));
};

/**
 * useArticles but for SSR
 */
export const getArticles = async ({ leagueUrl }: { leagueUrl: string }) => {
  const client = initializeApollo();

  let leagueConfigResponse: any = client.query({
    query: LEAGUE_CONFIG_QUERY,
    variables: {
      league: leagueUrl || "none",
      includeSurveyResponses: false,
    },
  });

  let infiniteArticlesResponse: any = client.query({
    query: INFINITE_ARTICLES_QUERY,
    variables: {
      first: 9,
      where: {
        hidden: false,
        league: leagueUrl || undefined,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  leagueConfigResponse = await leagueConfigResponse;
  infiniteArticlesResponse = await infiniteArticlesResponse;

  const leagueConfig = leagueConfigResponse.data?.prismicAllLeague_configs.edges[0]?.node || null; // prettier-ignore
  const featuredContent = prepareFeaturedContent(leagueConfig);
  const featuredArticles = featuredContent.filter((x) => x._meta.type === "news_basic"); // prettier-ignore

  const recentArticles = infiniteArticlesResponse.data?.prismicAllNews_basics.edges.map((e: any) => e.node) || []; // prettier-ignore

  const allArticles = featuredArticles.concat(recentArticles);
  const allArticlesFromNow = allArticles.map((article: any) => ({
    ...article,
    fromNow: moment.tz(article._meta.lastPublicationDate, DEFAULT_TIMEZONE).fromNow(),
  }));

  return dedupeArticles(allArticlesFromNow);
};

export const useIndexArticles = ({ leagueUrl }: { leagueUrl?: string }) => {
  const leagueConfigResponse = useQuery(LEAGUE_CONFIG_QUERY, {
    variables: {
      league: leagueUrl ?? "none",
      includeSurveyResponses: false,
    },
  });

  const infiniteArticlesResponse = useQuery(INFINITE_ARTICLES_QUERY, {
    variables: {
      first: 9,
      where: {
        league: leagueUrl ?? undefined,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const leagueConfig = leagueConfigResponse.data?.prismicAllLeague_configs.edges[0]?.node || null; // prettier-ignore
  const featuredContent = prepareFeaturedContent(leagueConfig);
  const featuredArticles = featuredContent.filter((x) => x._meta.type === "news_basic"); // prettier-ignore

  const recentArticles = infiniteArticlesResponse.data?.prismicAllNews_basics.edges.map((e: any) => e.node) || []; // prettier-ignore

  const allArticles = featuredArticles.concat(recentArticles);
  const allArticlesFromNow = allArticles.map((article: any) => ({
    ...article,
    fromNow: moment.tz(article._meta.lastPublicationDate, DEFAULT_TIMEZONE).fromNow(),
  }));

  return dedupeArticles(allArticlesFromNow);
};

/**
 * Surveys in featured content
 */
export const useSurveys = ({ ssr }: { ssr?: boolean } = {}) => {
  const featuredContent = useFeaturedContent({ ssr });
  return featuredContent.filter((x) => x._meta.type === "survey");
};

/**
 * Get the league object from the league as specified in Prismic news
 */
export const getLeagueFromPrismicNewsAlias = (league: string): WebSportItem | null => {
  // Remove the variable from the query
  if (!league) {
    return null;
  }

  // For news_basic, we have about 600 items with cbk and cfb instead of ncaab and ncaaf
  if (["cfb", "cbk"].includes(league)) {
    return WEB_SPORTS_LIST.find((x: any) => x.key === league.toUpperCase()) || null;
  }

  // Normally prismic uses the url
  return WEB_SPORTS_MAP[league];
};

export const dedupeArticles = (articles: any[]) => {
  const articleIds = [...new Set(articles.map((x: any) => x._meta.id))];
  return articleIds.map((id: any) => articles.find((x: any) => x._meta.id === id)); // eslint-disable-line
};

import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { Button } from "../Button";
import styles from "./styles.scss";
import { ADD_SURVEY_RESPONSE } from "../../gql/mutations";
import AnalyticsService from "../../services/AnalyticsService";
import { gql, useMutation, useReactiveVar } from "@apollo/client";
import { SubscriptionContext } from "src/services/SubscriptionService";
import { DEFAULT_SPORT } from "src/constants";
import { currentSport } from "src/services/ApolloService";

export interface SurveyProps {
  survey: any;
}

/**
 * This component renders a Survey or a Trend if their are no answers
 *
 * NOTE: we need the DOM elements to not be removed from the DOM in order for
 * animation to work, so we can't re-order the elements after the user votes.
 */
const Survey = ({ survey }: SurveyProps) => {
  const ctx = useContext(SubscriptionContext);
  const currentSportObj = useReactiveVar(currentSport);
  const sportKey = currentSportObj?.key ?? DEFAULT_SPORT.key;
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [addSurveyResponse] = useMutation(ADD_SURVEY_RESPONSE);
  const { response, question } = survey;

  // Hide login prompt
  useEffect(() => {
    if (ctx.signedIn && showLoginPrompt) {
      setShowLoginPrompt(false);
    }
  });

  // Submit survey
  const onSurveyRespond = async (answer: any) => {
    if (!ctx.signedIn) {
      setShowLoginPrompt(true);
      return;
    }

    if (survey.response) {
      return;
    }

    await addSurveyResponse({
      variables: {
        surveyId: survey._meta.id,
        answer: answer.answer,
      },
      // Update survey immediately
      update: (proxy) => {
        const thisSurvey = _.cloneDeep(survey);
        const thisAnswer = thisSurvey.answers.find((a: any) => a.answer === answer.answer); // eslint-disable-line
        thisSurvey.response = { answer: answer.answer };
        thisAnswer.count++;
        proxy.writeFragment({
          data: thisSurvey,
          fragment: gql`
            fragment MySurvey on PrismicSurvey {
              answers {
                answer
                count
              }
              response {
                answer
              }
            }
          `,
        });
      },
    });

    AnalyticsService.track("Survey Answer", {
      question: survey.question,
      answer: answer.answer,
      sport: sportKey,
    });
  };

  // Total answer count
  const totalCount = survey.answers.reduce((accum: number, answer: any) => accum + answer.count, 0); // eslint-disable-line

  // Answer display values
  let answers = survey.answers.map((item: any) => {
    const { id, answer, surveyId, count } = item;
    const percentage = (count * 100) / (totalCount || 1);
    return {
      id,
      answer,
      surveyId,
      percentage,
      percentageDisplay: percentage.toFixed(1) + "%",
    };
  });

  // Trend
  if (!answers.length) {
    return (
      <div className="d-none d-sm-block survey-item survey-item--white">
        <div className="survey-item__fact-text">Trend</div>
        <p className="survey-title survey-title--dark">{question}</p>
        <style jsx>{styles}</style>
      </div>
    );
  }

  const displaySurveyOrLogin = () => {
    if (showLoginPrompt && !ctx.signedIn) {
      return (
        <section className="login-container" aria-label="Login / Signup">
          <p className="login-prompt">Create a BetQL Account to see how the community voted!</p>
          <Button block className="login-button" onClick={() => ctx.openLoginModal(1)}>
            Log In / Sign Up
          </Button>
        </section>
      );
    } else {
      return (
        <section aria-label={question}>
          <p className="survey-title">{question}</p>
          <div className="answer-container">
            {answers.map((answer: any, idx: number) => {
              return (
                <button key={idx} className="answer-item" onClick={() => onSurveyRespond(answer)}>
                  <div
                    className={`progress-bar ${response?.answer === answer.answer ? "my-answer" : ""}`}
                    style={{ width: `${response ? answer.percentage : 0}%` }}
                  />
                  <div className="answer-text">{answer.answer}</div>
                  <div className="result-text">{response ? answer.percentageDisplay : ""}</div>
                </button>
              );
            })}
            <div className="footer-text">
              {
                !response &&
                  `Vote to see how${totalCount ? ` ${totalCount}` : ""} bettors have responded.` /*eslint-disable-line*/
              }
              {response && `${totalCount ? totalCount : ""} bettor responses.`}
            </div>
          </div>
        </section>
      );
    }
  };

  // Survey
  return (
    <section className="survey-item" aria-label="Survey-Item">
      {displaySurveyOrLogin()}
      <style jsx>{styles}</style>
    </section>
  );
};

export default Survey;

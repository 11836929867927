import React from "react";
import Survey from "../Survey";
import styles from "./styles.scss";
import { useSurveys } from "src/services/FeaturedContent";

function Surveys({ header = null }: { header?: any }) {
  const surveys = useSurveys({ ssr: false });

  if (!surveys.length) {
    return null;
  }

  return (
    <React.Fragment>
      {header}
      <section aria-label="Survey List" className="survey-list">
        {surveys.map((survey: any, idx: number) => (
          <Survey survey={survey} key={idx} />
        ))}
        <style jsx>{styles}</style>
      </section>
    </React.Fragment>
  );
}

export default Surveys;
